<template>
  <div>
    <div class="row">
      <span class="title">{{ title }}</span>
    </div>

    <div v-if="virtualPhones && selectedPhone" class="row">
      <b-dropdown
        ref="dropdown"
        left
        variant="outline-primary"
        class="no-border"
      >
        <template #button-content>
          <b>{{ selectedPhone.name }}</b>
          <span v-phone-number="selectedPhone.number" class="ml-2" />
        </template>

        <b-dropdown-form v-for="(phone) in virtualPhones" :key="phone.id" class="virtual-phone-item" @click="select(phone)">
          <div class="d-flex justify-content-between align-items-center">
            <b>{{ phone.name }}</b>
            <span v-phone-number="phone.number">{{ phone.number }}</span>
          </div>
        </b-dropdown-form>
      </b-dropdown>
    </div>
    <hr class="w-100">
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { phoneNumber } from '@/directives/directives'

export default {
  name: 'VirtualPhoneMasthead',

  directives: {
    phoneNumber,
  },

  props: {
    title: {
      type: String,
    },
  },

  computed: {
    ...mapGetters('virtualPhones', ['virtualPhoneById', 'virtualPhones', 'selectedPhone']),
  },

  async mounted () {
    await this.findOrSetUpVirtualPhoneId()
    this.$emit('loaded')
  },

  methods: {
    ...mapActions('virtualPhones', ['setSelectedPhoneId', 'loadVirtualPhones']),

    select(phone) {
      this.setSelectedPhoneId(phone.id)
      this.$refs.dropdown.hide(true)
      this.$emit('changed')
    },
    async findOrSetUpVirtualPhoneId(){
      let virtualPhoneIdFromURL = this.$route.params.virtualPhoneId
      if(virtualPhoneIdFromURL != undefined){
        await this.setSelectedPhoneId(virtualPhoneIdFromURL)
      }
      if(this.virtualPhones.length === 0){
        await this.loadVirtualPhones()
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.title {
  color: #707070;
  font-weight: 600;
  font-size: 18px;
  margin-left: 13px;
}

.no-border::v-deep .btn.dropdown-toggle {
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  color: #000864;
  font-size: 22px;
  background-color: transparent;
}

.no-border::v-deep .btn.dropdown-toggle:hover {
  border: none;
  background-color: transparent;
}

.virtual-phone-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 60px;
  &:not(:first-of-type) {
    border-top: $light-border-color solid 1px;
  }
  &:hover {
    background-color: $ct-ui-color-1;
  }
  form {
    display: flex;
    align-items: center;
    height: 60px;
    > div {
      width: 100%;
    }
  }
}

</style>
