import { render, staticRenderFns } from "./VirtualPhoneMasthead.vue?vue&type=template&id=8f31e732&scoped=true"
import script from "./VirtualPhoneMasthead.vue?vue&type=script&lang=js"
export * from "./VirtualPhoneMasthead.vue?vue&type=script&lang=js"
import style0 from "./VirtualPhoneMasthead.vue?vue&type=style&index=0&id=8f31e732&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f31e732",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8f31e732')) {
      api.createRecord('8f31e732', component.options)
    } else {
      api.reload('8f31e732', component.options)
    }
    module.hot.accept("./VirtualPhoneMasthead.vue?vue&type=template&id=8f31e732&scoped=true", function () {
      api.rerender('8f31e732', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/VirtualPhones/VirtualPhoneMasthead.vue"
export default component.exports