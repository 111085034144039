var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    ]),
    _vm.virtualPhones && _vm.selectedPhone
      ? _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "b-dropdown",
              {
                ref: "dropdown",
                staticClass: "no-border",
                attrs: { left: "", variant: "outline-primary" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _c("b", [_vm._v(_vm._s(_vm.selectedPhone.name))]),
                          _c("span", {
                            directives: [
                              {
                                name: "phone-number",
                                rawName: "v-phone-number",
                                value: _vm.selectedPhone.number,
                                expression: "selectedPhone.number",
                              },
                            ],
                            staticClass: "ml-2",
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2583334519
                ),
              },
              _vm._l(_vm.virtualPhones, function (phone) {
                return _c(
                  "b-dropdown-form",
                  {
                    key: phone.id,
                    staticClass: "virtual-phone-item",
                    on: {
                      click: function ($event) {
                        return _vm.select(phone)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center",
                      },
                      [
                        _c("b", [_vm._v(_vm._s(phone.name))]),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "phone-number",
                                rawName: "v-phone-number",
                                value: phone.number,
                                expression: "phone.number",
                              },
                            ],
                          },
                          [_vm._v(_vm._s(phone.number))]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _c("hr", { staticClass: "w-100" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }