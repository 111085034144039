<template>
  <div class="ctAudio">
    <div class="operate">
      <span v-if="!playing" @click="togglePlayback">
        <fa-icon icon="play" :style="{color: '6F6F6F'}" />
      </span>

      <span v-else @click="togglePlayback">
        <fa-icon icon="pause" :style="{color: '6F6F6F'}" />
      </span>

      <span class="display-time">
        {{ displayTime }}
      </span>
    </div>

    <div ref="slider" class="slider" @click="handleModifyProgress">
      <div class="progressInfo" />
      <div class="process" :style="{ width: progressWidth }" />
      <div ref="trunk" class="trunk" :style="{ left: progressLeft }" @click="clickOnTrunk">
        <div class="block" />
      </div>
    </div>
  </div>
</template>
<script>

import AudioMixin from '@/mixins/AudioMixin'

export default {
  name: 'CTAudioControls',
  mixins: [AudioMixin],
  data () {
    return {
      min: 0,
      max: 100,
      slider: null,
      trunk: null,
      percent: 0,
      rate: 1,
      isMute: true,
      curVolume: 0.5,
    }
  },
  computed: {
    totalWidth() {
      return '600px'
    },
    displayTime () {
      return this.seek > 0 ? this.toMMSS(this.seek) : this.toMMSS(this.duration)
    },
    scale () {
      return (this.percent - this.min) / (this.max - this.min)
    },
    progressWidth () {
      if (this.slider) {
        return this.slider.offsetWidth * this.scale + 'px'
      } else {
        return 0 + 'px'
      }
    },
    progressLeft () {
      if (this.slider) {
        return (
          this.slider.offsetWidth * this.scale -
          this.trunk.offsetWidth / 2 +
          'px'
        )
      } else {
        return 0 + 'px'
      }
    },
  },
  watch: {
    progress (progress) {
      if (!document.onmouseup) {
        this.percent = (Math.round(progress * 10000) / 100.0).toFixed(2)
      }
    },
  },
  mounted () {
    this.setVolume(this.curVolume)
    this.slider = this.$refs.slider
    this.trunk = this.$refs.trunk
  },
  methods: {
    handleModifyProgress (e) {
      if (e.target.className === 'slider' || e.target.className === 'process') {
        let scale = e.offsetX / this.slider.offsetWidth
        this.setProgress(scale)
      }
    },
    toMMSS (input) {
      if (typeof input !== 'number') return '00' + ':' + '00'

      let seconds = parseInt(input)
      let minutes = Math.floor(seconds / 60)
      seconds = seconds % 60

      if (minutes > 0 && minutes < 10) { minutes = '0' + minutes }
      if (seconds < 10) { seconds = '0' + seconds }

      return minutes + ':' + seconds
    },
    clickOnTrunk (e) {
      let progressWidth = parseInt(this.progressWidth)
      let disX = e.clientX
      document.onmousemove = e => {
        let newWidth = e.clientX - disX + progressWidth
        let scale = newWidth / this.slider.offsetWidth
        this.percent = Math.ceil((this.max - this.min) * scale + this.min)
        this.percent = Math.max(this.percent, this.min)
        this.percent = Math.min(this.percent, this.max)
      }
      document.onmouseup = () => {
        document.onmousemove = document.onmouseup = null
        this.setProgress(this.scale)
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.ctAudio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 300px;
  height: 40px;
  line-height: 40px;
  width: 100%;
}

.ctAudio span {
  font-size: 16px;
  color: #6F6F6F;
  cursor: pointer;
}

.operate {
  min-width: 70px;
  overflow: auto;
}

.operate .display-time {
  position: relative;
  top: -1px;
  font-size: 14px;
  cursor: default;
  color: #6F6F6F;
  margin-left: 6px;
}

.ctAudio .slider {
  position: relative;
  width: 90%;
  height: 14px;
  background: rgb(248, 247, 247);
  border-radius: 2px;
  margin-right: 30px;
  cursor: pointer;
}

.slider .process {
  position: absolute;
  left: 0;
  top: 0;
  width: 112px;
  height: 14px;
  border-radius: 2px;
  background: #409eff;
}

.slider .trunk {
  position: absolute;
  left: 100px;
  top: -3px;
  width: 8px;
  height: 12px;
}

.slider .block {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #409eff;
  background: rgba(255, 255, 255, 1);
  transition: 0.2s all;
}

.slider .block:hover {
  transform: scale(1.1);
  opacity: 0.6;
}

.slider .progressInfo {
  position: absolute;
  top: -28px;
  color: #117eeb;
  font-weight: 600;
}

</style>
