var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ctAudio" }, [
    _c("div", { staticClass: "operate" }, [
      !_vm.playing
        ? _c(
            "span",
            { on: { click: _vm.togglePlayback } },
            [
              _c("fa-icon", {
                style: { color: "6F6F6F" },
                attrs: { icon: "play" },
              }),
            ],
            1
          )
        : _c(
            "span",
            { on: { click: _vm.togglePlayback } },
            [
              _c("fa-icon", {
                style: { color: "6F6F6F" },
                attrs: { icon: "pause" },
              }),
            ],
            1
          ),
      _c("span", { staticClass: "display-time" }, [
        _vm._v("\n      " + _vm._s(_vm.displayTime) + "\n    "),
      ]),
    ]),
    _c(
      "div",
      {
        ref: "slider",
        staticClass: "slider",
        on: { click: _vm.handleModifyProgress },
      },
      [
        _c("div", { staticClass: "progressInfo" }),
        _c("div", {
          staticClass: "process",
          style: { width: _vm.progressWidth },
        }),
        _c(
          "div",
          {
            ref: "trunk",
            staticClass: "trunk",
            style: { left: _vm.progressLeft },
            on: { click: _vm.clickOnTrunk },
          },
          [_c("div", { staticClass: "block" })]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }