import { Howl } from "howler"
import clamp from "math-clamp"
import values from "object-values"
import assign from "object-assign"

/*
* Dev notes:
* Most of this came from https://github.com/forijk/vue-audio-better
* Their implementation was hard to work with and didn't really do what we want so I just copied
* the parts that I thought made sense for our project.
* */

export default {
  props: {
    audioSource: {
      type: String,
      required: true,
      validator(audioSource) {
        return typeof audioSource === "string" && audioSource.length > 0
      },
    },

    autoplay: {
      type: Boolean,
      default: false,
    },

    loop: {
      type: Boolean,
      default: false,
    },

    html5: {
      type: Boolean,
      default: false,
    },

    xhrWithCredentials: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {

      _howl: null,

      playing: false,

      muted: false,

      volume: 1.0,

      rate: 1.0,

      seek: 0,

      duration: 0,

      _polls: {
        seek: {
          id: null,
          interval: 1000 / 4, // 4 times per second (4Hz)
          hook: () => {
            this.seek = this.$data._howl.seek()
          },
        },
      },

      _howlEvents: [
        {
          name: "load",
          hook: () => {
            this.duration = this.$data._howl.duration()
          },
        },
        {
          name: "loaderror",
          hook: (e) => {
            console.log("loaderror:")
            console.log(e)
          },
        },
        {
          name: "playerror",
          hook: () => {
            console.log("playerror:")
            console.log(e)
          },
        },
        {
          name: "play",
          hook: () => {
            this.playing = true
          },
        },
        {
          name: "end",
          hook: () => {
            this.playing = false
          },
        },
        {
          name: "pause",
          hook: () => {
            this.playing = false
          },
        },
        {
          name: "stop",
          hook: () => {
            this.playing = false
            if (this.$data._howl != null) {
              this.seek = this.$data._howl.seek()
            }
          },
        },
        "mute",
        {
          name: "volume",
          hook: () => {
            this.volume = this.$data._howl.volume()
          },
        },
        {
          name: "rate",
          hook: () => {
            this.rate = this.$data._howl.rate()
          },
        },
        {
          name: "seek",
          hook: () => {
            if(!this.playing) this.seek = this.$data._howl.seek()
          },
        },
        "fade",
      ],
    }
  },

  computed: {
    progress() {
      if (this.duration === 0) return 0
      return this.seek / this.duration
    },
  },

  created() {
    this._initialize()
  },

  beforeDestroy() {
    this._cleanup()
  },

  watch: {
    playing(playing) {
      // Update the seek
      this.seek = this.$data._howl.seek()

      if (playing) {
        // Start the seek poll
        this.$data._polls.seek.id = setInterval(
          this.$data._polls.seek.hook,
          this.$data._polls.seek.interval
        )
      } else {
        // Stop the seek poll
        clearInterval(this.$data._polls.seek.id)
      }
    },

    audioSource() {
      this._reinitialize()
    },
  },

  methods: {
    _reinitialize() {
      this._cleanup(false)
      this._initialize()
    },

    _initialize() {
      this.$data._howl = new Howl({
        src: this.audioSource,
        volume: this.volume,
        html5: this.html5,
        loop: this.loop,
        autoplay: this.autoplay,
        mute: this.muted,
        rate: this.rate,
        format: 'wav',
        xhrWithCredentials: this.xhrWithCredentials,
      })

      console.log(this.$data)

      const duration = this.$data._howl.duration()
      this.duration = duration

      if (duration > 0) {
        // The audio file(s) have been cached. Howler won't
        // emit a load event, so we will do this manually
        this.$emit("load")
      }

      // Bind to all Howl events
      this.$data._howlEvents = this.$data._howlEvents.map(event => {
        // Normalize string shorthands to objects
        if (typeof event === "string") {
          event = { name: event }
        }

        // Create a handler
        const handler = (id, details) => {
          if (typeof event.hook === "function") event.hook(id, details)
          this.$emit(event.name, id, details)
        }

        // Bind the handler
        this.$data._howl.on(event.name, handler)

        // Return the name and handler to unbind later
        return assign({}, event, { handler })
      })
    },

    _cleanup(resetSettings = true) {
      // Stop all playback
      if (this.$data._howl) {
        this.stop()
      }

      // Stop all polls
      values(this.$data._polls).forEach(poll => {
        if (poll.id != null) clearInterval(poll.id)
      })

      // Clear all event listeners
      this.$data._howlEvents.map(event => {
        if (event.handler) {
          if (this.$data._howl) {
            this.$data._howl.off(event.name, event.handler)
          }

          const _event = assign({}, event)
          delete _event.handler
          return _event
        }

        return event
      })

      // Destroy the Howl instance
      this.$data._howl = null

      this.duration = 0

      if (resetSettings) {
        this.muted = false
        this.volume = 1.0
        this.rate = 1.0
      }
    },

    play() {
      if (!this.playing) this.$data._howl.play()
    },

    pause() {
      if (this.playing) this.$data._howl.pause()
    },

    togglePlayback() {
      console.log("HERE")
      console.log(this.$data._howl)

      if (!this.playing) {
        this.$data._howl.play()
      } else {
        this.$data._howl.pause()
      }
    },

    stop() {
      this.$data._howl.stop()
    },

    mute() {
      this.$data._howl.mute(true)
      this.muted = true
    },

    unmute() {
      this.$data._howl.mute(false)
      this.muted = false
    },

    toggleMute() {
      this.$data._howl.mute(!this.muted)
      this.muted = !this.muted
    },

    setVolume(volume) {
      if (typeof volume !== "number") {
        throw new Error(
          `volume must be a number, got a ${typeof volume} instead`
        )
      }

      this.$data._howl.volume(clamp(volume, 0, 1))
    },

    setRate(rate) {
      if (typeof rate !== "number") {
        throw new Error(`rate must be a number, got a ${typeof rate} instead`)
      }

      this.$data._howl.rate(clamp(rate, 0.5, 4))
    },

    setSeek(seek) {
      if (typeof seek !== "number") {
        throw new Error(`seek must be a number, got a ${typeof seek} instead`)
      }

      this.$data._howl.seek(clamp(seek, 0, this.duration))
    },

    setProgress(progress) {
      if (typeof progress !== "number") {
        throw new Error(
          `progress must be a number, got a ${typeof progress} instead`
        )
      }

      this.setSeek(clamp(progress, 0, 1) * this.duration)
    },
  },
}
